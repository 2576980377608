var _a, _b;
import { jsx as _jsx } from "react/jsx-runtime";
import { LazyCreateWorkspacePage, LazyNews, LazyWorkspacePage, LazyStrategiesPage, LazyWorkspacePreviewPage, LazyNewsTrends, LazySettingsPage, LazyPartnershipPage, LazyStrategiesCardPage, LazyPricingPage, LazyTickerPage, LazyHowItWorksPage,
// LazyLandingPage,
 } from '@/pages';
import { LazyLoginPage } from '@/pages/Login/ui/LazyLoginPage';
import { LazyRegisterPage } from '@/pages/Register/ui/LazyRegisterPage';
import { LazyResetPasswordPage } from '@/pages/ResetPassword/ui/LazyResetPassword';
import { LazyNewPasswordPage } from '@/pages/NewPasswordPage/ui/LazyNewPasswordPage';
import { AlternativeLanding } from '@/pages/AlternativeLanding/AlternativeLanding';
import { LazyNewLandingPage } from '@/pages/NewLandingPage/LazyNewLandingPage';
export var AppRoutes;
(function (AppRoutes) {
    AppRoutes["MAIN"] = "main";
    AppRoutes["ALTERNATIVELANDING"] = "alternativelanding";
    AppRoutes["WORKSPACE"] = "workspace";
    AppRoutes["PORTFOLIOS"] = "portfolios";
    AppRoutes["DATABASES"] = "databases";
    AppRoutes["STRATEGIES"] = "strategies";
    AppRoutes["NEWSTRENDS"] = "newsTrends";
    AppRoutes["NEWS"] = "news";
    AppRoutes["CONTACT_US"] = "contact_us";
    AppRoutes["WORKSPACEPREVIEW"] = "workspacePreview";
    AppRoutes["CREATEWORKSPACE"] = "createWorkspace";
    AppRoutes["SETTINGS"] = "settings";
    AppRoutes["STRATEGIESCARD"] = "strategiesCard";
    AppRoutes["LOGIN"] = "login";
    AppRoutes["REGISTER"] = "register";
    AppRoutes["PLANS"] = "plans";
    AppRoutes["RESETPASSWORD"] = "reset_password";
    AppRoutes["NEW_PASSWORD"] = "new_password";
    AppRoutes["TICKER"] = "ticker";
    AppRoutes["HOW_IT_WORKS"] = "how_it_works";
})(AppRoutes || (AppRoutes = {}));
export var RoutePath = (_a = {},
    _a[AppRoutes.MAIN] = '/',
    _a[AppRoutes.ALTERNATIVELANDING] = '/join',
    _a[AppRoutes.WORKSPACE] = '/workspace',
    _a[AppRoutes.PORTFOLIOS] = '/portfolios',
    _a[AppRoutes.DATABASES] = '/databases',
    _a[AppRoutes.STRATEGIES] = '/strategies',
    _a[AppRoutes.CONTACT_US] = '/contact_us',
    _a[AppRoutes.NEWS] = '/news/:id?',
    _a[AppRoutes.WORKSPACEPREVIEW] = '/workspacePreview',
    _a[AppRoutes.NEWSTRENDS] = '/newsTrends',
    _a[AppRoutes.CREATEWORKSPACE] = '/createWorkspace/:id',
    _a[AppRoutes.STRATEGIESCARD] = '/strategies/:id',
    _a[AppRoutes.SETTINGS] = '/settings',
    _a[AppRoutes.LOGIN] = '/sign-in',
    _a[AppRoutes.REGISTER] = '/sign-up',
    _a[AppRoutes.RESETPASSWORD] = '/reset-password',
    _a[AppRoutes.NEW_PASSWORD] = '/new-password',
    _a[AppRoutes.PLANS] = '/plans',
    _a[AppRoutes.TICKER] = '/ticker/:id',
    _a[AppRoutes.HOW_IT_WORKS] = '/how_it_works',
    _a);
export var routeConfig = (_b = {},
    _b[AppRoutes.MAIN] = {
        id: 1,
        path: RoutePath.main,
        element: _jsx(LazyNewLandingPage, {}),
        isApplyLayout: false,
        variant: { background: 'white' },
    },
    _b[AppRoutes.NEWSTRENDS] = {
        id: 2,
        path: RoutePath.newsTrends,
        element: _jsx(LazyNewsTrends, {}),
        variant: { background: 'white' },
    },
    _b[AppRoutes.CREATEWORKSPACE] = {
        id: 3,
        path: RoutePath.createWorkspace,
        isProtect: true,
        element: _jsx(LazyCreateWorkspacePage, {}),
        variant: {},
        isApplyLayout: false,
    },
    _b[AppRoutes.NEWS] = {
        id: 4,
        path: RoutePath.news,
        element: _jsx(LazyNews, {}),
        variant: { background: 'white' },
    },
    _b[AppRoutes.DATABASES] = {
        id: 5,
        path: RoutePath.databases,
        element: _jsx("div", {}),
        variant: {},
    },
    _b[AppRoutes.STRATEGIES] = {
        id: 6,
        path: RoutePath.strategies,
        element: _jsx(LazyStrategiesPage, {}),
        variant: { background: 'white' },
    },
    _b[AppRoutes.PORTFOLIOS] = {
        id: 7,
        path: RoutePath.portfolios,
        element: _jsx("div", {}),
        variant: {},
    },
    _b[AppRoutes.WORKSPACE] = {
        id: 8,
        path: RoutePath.workspace,
        isProtect: true,
        element: _jsx(LazyWorkspacePage, {}),
        variant: { background: 'gray' },
    },
    _b[AppRoutes.CONTACT_US] = {
        id: 9,
        path: RoutePath.contact_us + '/:formType?',
        isProtect: false,
        element: _jsx(LazyPartnershipPage, {}),
        variant: { background: 'white' },
        isApplyLayout: false,
    },
    _b[AppRoutes.WORKSPACEPREVIEW] = {
        id: 10,
        path: RoutePath.workspacePreview,
        isProtect: false,
        isApplyLayout: false,
        element: _jsx(LazyWorkspacePreviewPage, {}),
        variant: { background: 'white' },
    },
    _b[AppRoutes.STRATEGIESCARD] = {
        id: 11,
        path: RoutePath.strategiesCard,
        element: _jsx(LazyStrategiesCardPage, {}),
        variant: { background: 'white' },
    },
    _b[AppRoutes.SETTINGS] = {
        id: 12,
        path: RoutePath.settings,
        isProtect: true,
        element: _jsx(LazySettingsPage, {}),
        variant: { background: 'gray' },
    },
    _b[AppRoutes.LOGIN] = {
        id: 13,
        path: RoutePath.login,
        element: _jsx(LazyLoginPage, {}),
        variant: { background: 'white' },
        isApplyLayout: false,
    },
    _b[AppRoutes.REGISTER] = {
        id: 14,
        path: RoutePath.register,
        element: _jsx(LazyRegisterPage, {}),
        variant: { background: 'white' },
        isApplyLayout: false,
    },
    _b[AppRoutes.RESETPASSWORD] = {
        id: 15,
        path: RoutePath.reset_password,
        element: _jsx(LazyResetPasswordPage, {}),
        variant: { background: 'white' },
        isApplyLayout: false,
    },
    _b[AppRoutes.NEW_PASSWORD] = {
        id: 16,
        path: RoutePath.new_password,
        element: _jsx(LazyNewPasswordPage, {}),
        variant: { background: 'white' },
        isApplyLayout: false,
    },
    _b[AppRoutes.PLANS] = {
        id: 17,
        path: RoutePath.plans,
        element: _jsx(LazyPricingPage, {}),
        variant: { background: 'white' },
    },
    _b[AppRoutes.TICKER] = {
        id: 18,
        path: RoutePath.ticker,
        element: _jsx(LazyTickerPage, {}),
        variant: { background: 'gray' },
    },
    _b[AppRoutes.ALTERNATIVELANDING] = {
        id: 19,
        path: RoutePath.alternativelanding,
        element: _jsx(AlternativeLanding, {}),
        isApplyLayout: false,
        variant: {},
    },
    _b[AppRoutes.HOW_IT_WORKS] = {
        id: 20,
        path: RoutePath.how_it_works,
        element: _jsx(LazyHowItWorksPage, {}),
        isApplyLayout: true,
        variant: { background: 'white' },
    },
    _b);
export var routeGenerator = {
    createWorkspace: function (id) { return "".concat(RoutePath.createWorkspace.replace(':id', id)); },
};
