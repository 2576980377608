var _a;
import { styled } from '@/app/styles/mainStyles';
import { keyframes } from '@stitches/react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ReactComponent as ChevronIcon } from '@/shared/assets/icons/Chevron.svg';
var slideDown = keyframes({
    from: { maxHeight: 0 },
    to: { maxHeight: '500px' },
});
export var AccordionRoot = styled(AccordionPrimitive.Root, {
    height: '450px',
    '@mobile': {
        height: 'fit-content',
    },
    '@tablet': {
        height: 'fit-content',
    },
});
export var AccordionItem = styled(AccordionPrimitive.Item, {});
export var AccordionHeader = styled(AccordionPrimitive.Header, {});
export var Chevron = styled(ChevronIcon, {
    transition: 'transform 0.2s linear',
});
export var AccordionTrigger = styled(AccordionPrimitive.Trigger, {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: '24px',
    boxSizing: 'border-box',
    fontFamily: '$afacad',
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '38.4px',
    display: 'flex',
    color: '$primaryText',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '16px',
    width: '100%',
    border: '1px solid $controlsStrokeDefault',
    outline: 0,
    marginBottom: '16px',
    transition: 'border-radius 0s ease, border-bottom 0s ease',
    textAlign: 'left',
    '@mobile': {
        borderRadius: '16px',
        fontSize: '16px',
        lineHeight: '19px',
        maxWidth: '100%',
        padding: '16px 12px',
    },
    '@tablet': {
        height: '51px !important',
        borderRadius: '16px',
        fontSize: '16px',
        lineHeight: '19px',
        maxWidth: '100%',
        padding: '16px 12px',
    },
    '&[data-state="open"]': (_a = {
            fontWeight: 500,
            borderBottom: '1px solid transparent',
            borderRadius: '16px 16px 0 0'
        },
        _a["".concat(Chevron)] = {
            transform: 'rotate(180deg)',
        },
        _a),
    '&[data-state="closed"]': {
        borderRadius: '16px',
        fontWeight: 400,
        border: '1px solid $controlsStrokeDefault',
    },
});
export var AccordionContent = styled(AccordionPrimitive.Content, {
    overflow: 'hidden',
    marginTop: '-16px',
    marginBottom: '16px',
    fontFamily: '$afacad',
    fontSize: '32px',
    fontWeight: 400,
    borderRadius: '0 0 16px 16px',
    border: '1px solid $controlsStrokeDefault',
    borderTop: 0,
    lineHeight: '38.4px',
    padding: '0 24px 24px 24px',
    color: '$alwaysWhite',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    maxHeight: 0,
    '@mobile': {
        fontSize: '16px',
        lineHeight: '19px',
        padding: '0px 12px 16px 12px',
        borderRadius: '0 0 16px 16px',
    },
    '@tablet': {
        borderRadius: '0 0 16px 16px',
        fontSize: '16px',
        lineHeight: '19px',
        padding: '0px 12px 16px 12px',
    },
    '&[data-state="open"]': {
        animation: "".concat(slideDown, " 0.5s linear"),
        maxHeight: '500px',
    },
});
