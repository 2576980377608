import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
export var getData = function () { return [
    [
        {
            id: 'Strategies',
            title: 'Strategies',
            path: RoutePath.strategies,
        },
        {
            id: 'Workspace',
            title: 'Workspace',
            path: RoutePath.workspace,
        },
        {
            id: 'News',
            title: 'News',
            path: RoutePath.news,
        },
        {
            id: 'Trends',
            title: 'Trends',
            path: RoutePath.newsTrends,
        },
        {
            id: 'Pricing',
            title: 'Pricing',
            path: RoutePath.plans,
        },
        {
            id: 'Partnership',
            title: 'Partnership',
            path: RoutePath.contact_us,
        },
    ],
    [
        {
            id: 'HelpCenter',
            title: 'Help Center',
            path: '/',
        },
        {
            id: 'API',
            title: 'API',
            path: '/',
        },
        {
            id: 'PrivacyPolicy',
            title: 'Privacy Policy',
            path: '/',
        },
        {
            id: 'Terms & Conditions',
            title: 'Terms & Conditions',
            path: '/',
        },
    ],
]; };
