var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import React, { useState } from 'react';
import { AvatarWithName, Dropdown, Space } from '@/shared/ui';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
import { DropdownHeader } from '@/widgets/ui/Header/ui/DropdownHeader/DropdownHeader';
import { SearchDialog } from '@/widgets/ui/Landing/SearchDialog/SearchDialog';
import { ThemeToggle } from '@/shared/ui/ThemeToggle/ThemeToggle';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
var HeaderProfile = function (_a) {
    var token = _a.token, userData = _a.userData;
    var history = useHistory();
    var isMobile = window.matchMedia('(max-width: 1279px)').matches;
    var _b = useState(false), searchOpen = _b[0], setSearchOpen = _b[1];
    var handleLogIn = function () {
        sessionStorage.setItem('prevPath', window.location.pathname);
        history.push(RoutePath.login);
    };
    var subscription = useTypedSelector(function (state) { return state.userReducer.userSubscription; });
    var navigateToWorkspace = function () {
        if (!token) {
            history.push("".concat(RoutePath.contact_us, "/workspace-access"));
            return;
        }
        if ((subscription === null || subscription === void 0 ? void 0 : subscription.count) !== 0) {
            history.push(RoutePath.workspace);
        }
        else {
            history.push("".concat(RoutePath.contact_us, "/workspace-access"));
        }
    };
    return (_jsxs(S.HeaderProfileContainer, { children: [token ?
                _jsx(_Fragment, { children: _jsx(S.AvatarWrapper, { children: !isMobile ? (_jsx(DropdownHeader, { trigger: _jsx(AvatarWithName, { userData: userData }) })) : (_jsx(AvatarWithName, { userData: userData })) }) })
                :
                    _jsx(_Fragment, { children: _jsxs(Space, __assign({ align: "center", size: "medium" }, { children: [!isMobile && (_jsxs(Dropdown, __assign({ trigger: _jsx(S.User, {}), disableChevron: true, cssContent: {
                                        left: '-80%',
                                        width: '205px',
                                    }, openType: "click", active: false }, { children: [_jsxs(S.UserAction, __assign({ onClick: handleLogIn }, { children: [_jsx(S.SignIn, {}), "Sign in"] })), _jsx(S.Divider, {}), _jsxs(S.UserAction, __assign({ css: {
                                                justifyContent: 'space-between',
                                            } }, { children: ["Theme", _jsx(ThemeToggle, {})] }))] }))), _jsx(S.StyledButton, __assign({ onClick: navigateToWorkspace }, { children: "Get started" }))] })) }), _jsx(SearchDialog, { open: searchOpen, close: function () { return setSearchOpen(false); } })] }));
};
export { HeaderProfile };
